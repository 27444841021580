import React from "react"
import { PRIVACY_LINK, EMAIL } from "../components/layout"
import Layout from "../components/layout"

import SEO from "../components/seo"
import "../styles/TermsofService.css"


//SHOULD Layout, be myLayout? - i believe so + ON 404 page
const TermsOfService = () => (

  <Layout>
    <SEO title="Terms of Service" />
     {/*Actual Doc stored in Google Docs, then export > HTML > Format better https://htmlformatter.com/ > and put here*/}
     {/*Move css to styles/TermsofService.css */}
     {/*Do not accidentally delete PRIVACY_LINK or EMAIL variables below, when updating this document*/}
     {/*Do not Delete: Both Google Play + iOS store use have a reference to this web page*/}
     <div style={{
        //wrapped everything in padding so it looks better
        paddingLeft: 100,
        paddingRight: 100,
       }}
       >
         <script>    
           console.log('PRIVACY_LINK: ', PRIVACY_LINK);
           console.log('PRIVACY_LINK2: ', {PRIVACY_LINK});
</script>
       
      <h1 class="c1" id="h.wqa6zkrtlzca"><span class="c23">Terms of Service</span></h1>
    <p class="c14 c12"><span class="c0">Last Revised: March 19, 2020</span></p>
    <p class="c14 c12"><span class="c0">The following document outlines the terms of use of the Fork More app and website. Before using any of the Fork More services, you are required to read, understand and agree to these terms. You may only create an account after reading and accepting these terms. By viewing the site or logging into the application you represent that you have the authority and capacity to enter into these terms. If you disagree with any provision of these terms, do not log into and/or use our services. </span></p>
    <h2 class="c9" id="h.8j0q2w762eiw"><span class="c18">Accounts</span></h2>
    <p class="c4"><span class="c5">Account Responsibilities.</span><span class="c17">&nbsp;</span><span class="c13">You are responsible for maintaining the confidentiality of your Account login information and are fully responsible for all activities that occur under your Account. You approve to immediately notify Tech Monster Co LLC. of any unauthorized use, or suspected unauthorized use of your Account. &nbsp;Tech Monster Co LLC. cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements.</span></p>
    <h2 class="c1" id="h.m73deu5wzqbi"><span class="c3">Eligibility</span></h2>
    <p class="c14 c12"><span class="c0">You must be at least 13 years old to use the site or services.</span></p>
    <h2 class="c1" id="h.wz9e82ieqwn8"><span class="c3">Privacy Policy</span></h2>
    <p class="c14 c12"><span class="c13">Please review our </span><span class="c19">
    
     {/*Warning below contains special variable PRIVACY_LINK, do NOT delete it when updating text in this document*/}
     <a class="c16" href={PRIVACY_LINK}>privacy policy</a>

      </span><span class="c0">&nbsp;for our practices concerning the handling of your personal information.</span></p>
    <h2 class="c1" id="h.5js4ez8i2z8v"><span class="c3">Ownership</span></h2>
    <p class="c14 c12"><span class="c0">The Fork More app and website are owned and operated by Tech Monster Co LLC., a Pennsylvania corporation.</span></p>
    <h2 class="c1" id="h.yxamlwuv9x7s"><span class="c3">Changes to Services</span></h2>
    <p class="c14 c12"><span class="c0">We may change or discontinue the site or services at any time, with or without notice to you.</span></p>
    <p class="c14 c12"><span class="c13">You approved that Tech Monster Co, LLC. will not be held liable to you or any third-party for any change, interruption, or termination of the Site or App or Service or any part.</span></p>
    <h2 class="c1" id="h.mhnv3d8xsxge"><span class="c3">Termination</span></h2>
    <p class="c14 c12"><span class="c13">We reserve the right not to provide the site or services to any person. We also reserve the right to suspend or terminate any user&#39;s right to access the site or services at any time </span><span class="c10">&nbsp;for any reason</span><span class="c13">, at our </span><span class="c10">sole </span><span class="c13">discretion</span><span class="c10">, including for any use of the Site in violation of these Terms</span><span class="c0">. If you violate the terms of this agreement, your permission to use the site and services terminates.</span></p>
    <p class="c14 c12"><span class="c10">&nbsp;</span><span class="c13">Upon termination of your rights under these Terms, your Account and right to access and use the Site will terminate immediately. &nbsp;You understand that any termination of your Account may involve deletion of your User Content associated with your Account from our live databases. &nbsp;Tech Monster Co, LLC. will not have any liability whatsoever to you for any termination of your rights under these Terms. &nbsp;Even after your rights under these Terms are terminated, some provisions of these Terms may remain in effect.</span></p>
    <h2 class="c1" id="h.p8ug5p25ulxh"><span class="c3">Limitation of Liability</span></h2>
    <p class="c14 c12"><span class="c0">You use the site and services at your own risk. The site and services are provided on an &quot;as is&quot; and &quot;as available&quot; basis. To the fullest extent permitted by applicable law, Tech Monster Co, LLC. and its officers, employees, directors, shareholders, parents, subsidiaries, affiliates, agents, and licensors disclaims all warranties, conditions, and representations of any kinds, whether express, implied, statutory, or otherwise, including those relation to merchantability, fitness for a particular purpose, and non-infringement and those arising out of course of dealing, usage, or trade.</span></p>
    <p class="c4"><span class="c0">To the maximum extent permitted by law, in no event shall Tech Monster Co, LLC. be liable under contract, tort, strict liability, negligence or other legal theory with respect to the site, the service or any content to you or any third-party for any lost profits, lost data, costs of procurement of substitute products, or any indirect, consequential, exemplary, incidental, special or punitive damages arising from or relating to these terms or your use of, or incapability to use the site even if Tech Monster Co LLC. has been advised of the possibility of such damages. &nbsp;</span></p>
    <p class="c4"><span class="c0">Access to and use of the site is at your own discretion and risk, and you will be solely responsible for any damage to your device or computer system, or loss of data resulting therefrom.</span></p>
    <p class="c4"><span class="c0">To the maximum extent permitted by law, notwithstanding anything to the contrary contained herein, our liability to you for any damages arising from or related to this agreement, will at all times be limited to a maximum of one U.S. dollar (u.s. $1). The existence of more than one claim will not enlarge this limit. &nbsp;You agree that our suppliers will have no liability of any kind arising from or relating to this agreement.</span></p>
    <p class="c4"><span class="c0">Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages, so the above limitation or exclusion may not apply to you.</span></p>
    <p class="c14 c12"><span class="c5 c12">No Support or Maintenance.</span><span class="c10">&nbsp;</span><span class="c0">You agree that Tech Monster Co, LLC. will have no obligation to provide you with any support in connection with the Site.</span></p>
    <p class="c14 c12 c15"><span class="c0"></span></p>
    <h2 class="c9" id="h.yg6j153nk0nf"><span class="c18">User Content</span></h2>
    <p class="c4"><span class="c5">User Content.</span><span class="c0">&nbsp;&ldquo;User Content&rdquo; means any and all information and content that a user submits to the Site. You are exclusively responsible for your User Content. You bear all risks associated with use of your User Content. &nbsp;You hereby certify that your User Content does not violate our Acceptable Use Policy. &nbsp;You may not represent or imply to others that your User Content is in any way provided, sponsored or endorsed by Tech Monster Co LLC. Because you alone are responsible for your User Content, you may expose yourself to liability. Tech Monster Co LLC. is not obliged to backup any User Content that you post; also, your User Content may be deleted at any time without prior notice to you. You are solely responsible for making your own backup copies of your User Content if you desire.</span></p>
    <p class="c4"><span class="c13">You hereby grant to Tech Monster Co LLC. an irreversible, nonexclusive, royalty-free and fully paid, worldwide license to reproduce, distribute, publicly display and perform, prepare derivative works of, incorporate into other works, and otherwise use and exploit your User Content, and to grant sublicenses of the foregoing rights, solely for the purposes of including your User Content in the Site. &nbsp;You hereby irreversibly waive any claims and assertions of moral rights or attribution with respect to your User Content.</span></p>
    <p class="c4"><span class="c5">Acceptable Use Policy.</span><span class="c0">&nbsp;The following terms constitute our &ldquo;Acceptable Use Policy&rdquo;: You agree not to use the Site to collect, upload, transmit, display, or distribute any User Content (i) that violates any third-party right or any intellectual property or proprietary right; (ii) that is unlawful, harassing, abusive, tortious, threatening, harmful, invasive of another&#39;s privacy, vulgar, defamatory, false, intentionally misleading, trade libelous, pornographic, obscene, patently offensive, promotes racism, bigotry, hatred, or physical harm of any kind against any group or individual; (iii) that is harmful to minors in any way; or (iv) that is in violation of any law, regulation, or obligations or restrictions imposed by any third party.</span></p>
    <p class="c4"><span class="c0">In addition, you agree not to: (i) upload, transmit, or distribute to or through the Site any software intended to damage or alter a computer system or data; (ii) send through the Site unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of duplicative or unsolicited messages; (iii) use the Site to harvest, collect, gather or assemble information or data regarding other users without their consent; (iv) interfere with, disrupt, or create an undue burden on servers or networks connected to the Site, or violate the regulations, policies or procedures of such networks; (v) attempt to gain unauthorized access to the Site, whether through password mining or any other means; (vi) harass or interfere with any other user&#39;s use and enjoyment of the Site; or (vi) use software or automated agents or scripts to produce multiple accounts on the Site, or to generate automated searches, requests, or queries to the Site.</span></p>
    <p class="c4"><span class="c0">We reserve the right to review any User Content, and to investigate and/or take appropriate action against you in our sole discretion if you violate the Acceptable Use Policy or any other provision of these Terms or otherwise create liability for us or any other person. Such action may include removing or modifying your User Content, terminating your Account, and/or reporting you to law enforcement authorities.</span></p>
    <p class="c4"><span class="c0">If you provide Tech Monster Co LLC. with any feedback or suggestions regarding the Site, you hereby assign to Tech Monster Co LLC. all rights in such Feedback and agree that Tech Monster Co LLC. shall have the right to use and fully exploit such Feedback and related information in any manner it believes appropriate. &nbsp;Tech Monster Co LLC. will treat any Feedback you provide to Tech Monster Co LLC. as non-confidential and non-proprietary.</span></p>
    <p class="c4"><span class="c0">You agree to indemnify and hold Tech Monster Co LLC. and its officers, employees, and agents harmless, including costs and attorneys&#39; fees, from any claim or demand made by any third-party due to or arising out of (a) your use of the Site, (b) your violation of these Terms, (c) your violation of applicable laws or regulations or (d) your User Content. &nbsp;Tech Monster Co LLC. reserves the right to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate with our defense of these claims. &nbsp;You agree not to settle any matter without the prior written consent of Tech Monster Co LLC. Tech Monster Co LLC. will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.</span></p>
    <h2 class="c9" id="h.7n5jm0ehej8j"><span class="c7">Disclaimers</span></h2>
    <h2 class="c11" id="h.3hleg5hes1e7"><span class="c0">The site and service is provided on an &ldquo;as-is&rdquo; and &ldquo;as available&rdquo; basis, and Tech Monster Co LLC. and our suppliers expressly disclaim any and all warranties and conditions of any kind, whether express, implied, or statutory, including all warranties or conditions of merchantability, fitness for a particular purpose, title, quiet enjoyment, accuracy, or non-infringement. &nbsp;We and our suppliers make not guarantee that the site will meet your requirements, will be available on an uninterrupted, timely, secure, or error-free basis, or will be accurate, reliable, free of viruses or other harmful code, complete, legal, or safe. &nbsp;If applicable law requires any warranties with respect to the site, all such warranties are limited in duration to ninety (90) days from the date of first use.</span></h2>
    <h2 class="c11" id="h.4g9vmstpurms"><span class="c0">Some jurisdictions do not allow the exclusion of implied warranties, so the above exclusion may not apply to you. &nbsp;Some jurisdictions do not allow limitations on how long an implied warranty lasts, so the above limitation may not apply to you.</span></h2>
    <h2 class="c9" id="h.gtzfl7v2enm8"><span class="c7">Third-Party Links, Ads, and Other Users</span></h2>
    <p class="c4"><span class="c5">Third-Party Links &amp; Ads.</span><span class="c0">&nbsp;The Fork More Application and/or Site may contain links to third-party websites and services, and/or display advertisements for third-parties. Such Third-Party Links &amp; Ads are not under the control of Tech Monster Co LLC., and Tech Monster Co LLC. is not responsible for any Third-Party Links &amp; Ads. Tech Monster Co LLC. provides access to these Third-Party Links &amp; Ads only as a convenience to you, and does not review, approve, monitor, endorse, warrant, or make any representations with respect to Third-Party Links &amp; Ads. You use all Third-Party Links &amp; Ads at your own risk, and should apply a suitable level of caution and discretion in doing so. When you click on any of the Third-Party Links &amp; Ads, the applicable third party&#39;s terms and policies apply, including the third party&#39;s privacy and data gathering practices.</span></p>
    <p class="c4"><span class="c5">Other Users.</span><span class="c0">&nbsp;Each Site user is solely responsible for any and all of its own User Content. &nbsp;Because we do not control User Content, you acknowledge and agree that we are not responsible for any User Content, whether provided by you or by others. &nbsp;You agree that Tech Monster Co LLC. will not be responsible for any loss or damage incurred as the result of any such interactions. &nbsp;If there is a dispute between you and any Site user, we are under no obligation to become involved.</span></p>
    <p class="c4"><span class="c0">You hereby release and forever discharge the Tech Monster Co LLC. and our officers, employees, agents, successors, and assigns from, and hereby waive and relinquish, each and every past, present and future dispute, claim, controversy, demand, right, obligation, liability, action and cause of action of every kind and nature, that has arisen or arises directly or indirectly out of, or that relates directly or indirectly to, the Application or Site. If you are a California resident, you hereby waive California civil code section 1542 in connection with the foregoing, which states: &ldquo;a general release does not extend to claims which the creditor does not know or suspect to exist in his or her favor at the time of executing the release, which if known by him or her must have materially affected his or her settlement with the debtor.&rdquo;</span></p>
    <p class="c4"><span class="c5">Cookies and Web Beacons.</span><span class="c13">&nbsp;Like any other website, </span><span class="c13">Fork More application or website may</span><span class="c0">&nbsp;use &lsquo;cookies&#39;. These cookies are used to store information including visitors&#39; preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users&#39; experience by customizing our web page content based on visitors&#39; browser type and/or other information.</span></p>
    <h2 class="c1" id="h.4zdnh0mzs0xe"><span class="c3">General</span></h2>
    <p class="c22 c15"><span class="c21"></span></p>
    <p class="c14 c12"><span class="c5 c12">Copyright/Trademark Information.</span><span class="c10">&nbsp;</span><span class="c0">Copyright &copy;. All rights reserved. &nbsp;All trademarks, logos and service marks displayed on the Application or Site are property of &nbsp;Tech Monster Co LLC. or the property of other third-parties. You are not permitted to use these Marks without our prior written consent or the consent of such third party which may own the Marks.</span></p>
    <h2 class="c9" id="h.na4uun68h9h6"><span class="c5 c12">Copyright Policy.</span></h2>
    <p class="c4"><span class="c13">Tech Monster Co LLC.</span><span class="c0">&nbsp;respects the intellectual property of others and asks that users of our Site and Services do the same. &nbsp;In connection with our Site and Services, we have adopted and implemented a policy respecting copyright law that provides for the removal of any infringing materials and for the termination of users of our online Site who are repeated infringers of intellectual property rights, including copyrights. &nbsp;If you believe that one of our users is, through the use of our Site, unlawfully infringing the copyright(s) in a work, and wish to have the allegedly infringing material removed, the following information in the form of a written notification (pursuant to 17 U.S.C. &sect; 512(c)) must be provided to our designated Copyright Agent:</span></p>
    <ul class="c20 lst-kix_i5kxopoawvxx-0 start">
        <li class="c8"><span class="c0">your physical or electronic signature;</span></li>
        <li class="c8"><span class="c0">identification of the copyrighted work(s) that you claim to have been infringed;</span></li>
        <li class="c8"><span class="c0">identification of the material on our services that you claim is infringing and that you request us to remove;</span></li>
        <li class="c8"><span class="c0">sufficient information to permit us to locate such material;</span></li>
        <li class="c8"><span class="c0">your address, telephone number, and e-mail address;</span></li>
        <li class="c8"><span class="c0">a statement that you have a good faith belief that use of the objectionable material is not authorized by the copyright owner, its agent, or under the law; and</span></li>
        <li class="c8"><span class="c0">a statement that the information in the notification is accurate, and under penalty of perjury, that you are either the owner of the copyright that has allegedly been infringed or that you are authorized to act on behalf of the copyright owner.</span></li>
    </ul>
    <p class="c4"><span class="c0">Please note that, pursuant to 17 U.S.C. &sect; 512(f), any misrepresentation of material fact in a written notification automatically subjects the complaining party to liability for any damages, costs and attorney&#39;s fees incurred by us in connection with the written notification and allegation of copyright infringement.</span></p>
    <p class="c14 c15 c12"><span class="c2"></span></p>
    <p class="c14 c12"><span class="c5 c12">Electronic Communications.</span><span class="c0">&nbsp;The communications between you and Tech Monster Co LLC. use electronic means, whether you use the Site or send us emails, or whether Tech Monster Co LLC. posts notices on the Site or communicates with you via email. For contractual purposes, you (a) consent to receive communications from Tech Monster Co LLC. in an electronic form; and (b) agree that all terms and conditions, agreements, notices, disclosures, and other communications that Tech Monster Co LLC. provides to you electronically satisfy any legal obligation that such communications would satisfy if it were be in a hard copy writing.</span></p>
    <h2 class="c1" id="h.8dxmscnl0wyw"><span class="c3">Other Provisions</span></h2>
    <p class="c14 c12"><span class="c0">These terms will be governed by and construed in accordance with the laws of the State of California, without giving effect to any conflict of laws rules or provisions.</span></p>
    <p class="c14 c12"><span class="c0">You agree that any action of whatever nature arising from or relating to these terms, the site, or services will be filed only in the state or federal courts located in Santa Clara County, California. You consent and submit to the personal jurisdiction of such courts for the purposes of any such action.</span></p>
    <p class="c14 c12"><span class="c0">If any provision of these terms is found to be unlawful, void, or unenforceable, then that provision will be deemed severable from these terms and will not affect the validity or enforceability of any remaining provisions.</span></p>
    <p class="c14 c12"><span class="c0">Our failure to exercise or enforce any right or provision of these terms will not prevent us from enforcing such right or provision in the future.</span></p>
    <p class="c14 c12"><span class="c0">We may assign our rights and obligations under these terms, including in connection with a merger, acquisition, sale of assets or equity, or by operation of law.</span></p>
    <h2 class="c1" id="h.svaogvj4yzkc"><span class="c3">Changes to Terms</span></h2>
    <p class="c14 c12"><span class="c0">From time to time, we may change these terms. If we change these terms, we will give you notice by posting the revised terms on the site. Those changes will go into effect on the revision date shown in the revised terms. By continuing to use the site or services, you agree to the revised terms.</span></p>
    <h2 class="c1" id="h.x7t9d81scpaq"><span class="c3">Contact</span></h2>
    <p class="c12 c14"><span class="c0">You may contact us about these terms in via email at the following address:</span></p>

    {/*Warning below contains special variable EMAIL, do NOT delete it when updating text in this document*/}
    <p class="c14 c12"><span class="c13">Email: </span><span class="c19 c24">{EMAIL}</span></p>

    <p class="c15 c22"><span class="c21"></span></p>
    </div>
   </Layout>
)

export default TermsOfService
